import React from "react";

import { useGetEventsQuery } from "features/events/eventsApi";

import constants from "../../app-constants";
import LoadingContainer from "../../components/LoadingContainer";
import { EventDetail, Tournament } from "../../models/Events";
import { useGetTournamentsQuery } from "./tournamentApi";
import TournamentDetail from "./TournamentDetail";

const TournamentList: React.FC = () => {
  const { data: events, isLoading: eventsLoading } = useGetEventsQuery(constants.EventCalendarYear)
  const { tournaments, isLoading } = useGetTournamentsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      tournaments: data?.map((t) => new Tournament(t)),
      isLoading,
    }),
  })

  return (
    <LoadingContainer loading={isLoading || eventsLoading}>
      {events?.map((evt) => {
        const tournament = tournaments?.find((t) => t.id === evt.tournament.id)
          const eventInstance = new EventDetail(evt)
          return (
            <TournamentDetail
              key={tournament.id}
              tournament={tournament}
              logoUrl={eventInstance.location?.logoUrl}
            />
          )
      })}
    </LoadingContainer>
  )
}

export default TournamentList
